import React, { useEffect, useState } from 'react'
import Map from './Map'

function ContactUs() {
  const [isFormEnabled, setIsFormEnabled] = useState(false);


  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if ( window.location.search.includes('success=true') ) {
      setSuccess(true);
    }
  }, []);
  return (
    <section className='about-us' id="contactus">
      <div className='container'>
        <div className='main'>
          <div className='textDiv'>
          {success && (
          <p style={{ color: "green" }}>Thanks for your message! </p>
        )}
            <span className='text1 generalHeader'>CONTACT US</span>
            <span className='text2'>PLEASE FILL IN YOUR DETAILS BELOW FOR FREE CONSULTATION</span>
            <span className='text3'>Our clients are our TOP Priority, we provide NDIS support 24/7</span>
            <span className='text4'>WELLBEING CARE GROUP</span>
            <span className='text5'>Roxburgh Park, Victoria, Australia</span>
            <span className='text6'>Phone: <a rel="" role="link" aria-haspopup="false" href="tel:+61401207720">+61 401 20 77 20</a></span>
            <span className='text7'>Email : <a rel="" role="link" aria-haspopup="false" href="mailto:info@wellbeingcg.com.au">info@wellbeingcg.com.au</a></span>
            
            <button className='btn-wbc btn-wbc-primary' onClick={()=> setIsFormEnabled(true)}>FREE CONSULTATION</button>
            {
              isFormEnabled && (
                <form 
       name="contact" 
       method="POST" 
       data-netlify="true" 
       action="/success=true"
      >
     <input type="hidden" name="form-name" value="contact" />
        <input id="standard-basic" label="name" name="name" />
        <input id="standard-basic" label="email" name="email" />
        <input multiline id="standard-basic" label="message" name="message" />
        <button type="submit">Send</button>
       </form>
              )
            }
          <div>
          
          </div>
          </div>
          <div className='textMap'>
          <Map/>
          </div>
        </div>
      </div>
        
    </section>
  )
}

export default ContactUs