import React from 'react'

function Reviews() {
  return (
    <section className='rev-sect'>
        <div className='container'>
            <div className='main'>
                <div className='title generalHeader'>
            REVIEWS 
            </div>
            <div className='rev-list'>
            Reviews coming soon!
            </div>
            </div>
            
        </div>

    </section>
  )
}

export default Reviews