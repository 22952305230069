import React from 'react'

function Footer() {
  return (
    <footer>
      COPYRIGHT © 2023 WELLBEING CARE GROUP - ALL RIGHTS RESERVED.
    </footer>
  )
}

export default Footer