import React from "react";
import logo from "../static/header/logo.webp";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header>
      <div className="container">
        <Link to="/" className="img">
          <img src={logo} alt="logo" />
        </Link>
      </div>
    </header>
  );
}

export default Header;
