import React from 'react'
import { Col, Row } from 'react-bootstrap';
import approach1 from "../../static/home/approach1.webp";
import approach2 from "../../static/home/approach2.webp";
import approach3 from "../../static/home/approach3.webp";

function OurApproach() {
    let list = [
        {
            id: 1,
            title: "Our Mission",
            text: "Our experienced professionals put your healing needs first. We are proud to render high-quality NDIS core and capacity building services. We are committed to quality service and wellness of our participants. Our mission is to empower you to live a quality life in excellence.",
            img: approach1
        },{
            id: 2,
            title: "Experience and Professionalism",
            text: "With years of experience, our team will assess your NDIS goals and liaise with allied health professional to create a custom recovery plan that's right for you. We understand the importance of educating you on the most effective ways to take care of your body and mind as quickly as possible.",
            img: approach2
        },{
            id: 3,
            title: "Your WellBeing",
            text: "Not only our therapist and support staff treat your existing conditions, but also work to maximize your prevention strategies. We strive to help you improve your quality of life, achieve your wellness goals, independence and best possible life.",
            img: approach3
        },
    ]
  return (
    <section className='ourapproach'>
        <div className='container'>
            <div className='generalHeader'>
                Our Approach
            </div>
            <Row>
                {
                    list.map((approach)=>(
                        <Col xs={12} sm={4} key={approach.id} className='approachitem'>
                            <div className='main'>
                                <div className='img'>
                                    <img src={approach.img} alt="sd" />
                                </div>
                                <div className='title'>
                                {approach.title}
                                </div>
                                <div className='para'>
                                    {approach.text}
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </div>
    </section>
  )
}

export default OurApproach