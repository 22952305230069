import React, { useEffect } from "react";
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken =
  "pk.eyJ1Ijoib21haXJhciIsImEiOiJjbGxjbmM0YWgwMzV4M2NsZGZoMzZuY2syIn0.jsVNk8O27W1PtTA63jiOFw";

function Map() {
  let lat = -37.61916181583457;
  let long = 144.927838960673;
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [long, lat],
      zoom: 15,
    });

    const marker = new mapboxgl.Marker().setLngLat([long, lat]).addTo(map);

    // Add zoom controls
    const zoomControls = new mapboxgl.NavigationControl();
    map.addControl(zoomControls, "top-right");
  }, []);
  return (
    
      <div id="map"  />
  
  );
}

export default Map;
