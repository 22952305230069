import React from 'react';
import heroImg from "../static/home/hero.webp";
import herologo from "../static/home/herologo.png";
import OurApproach from '../components/HomePage/OurApproach';
import Reviews from '../components/HomePage/Reviews';
import ContactUs from '../components/HomePage/ContactUs';
import Gallery from '../components/HomePage/Gallery';

function Home() {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='main w-100'>
            <img src={heroImg} className='w-100' alt="" />
            <div className='text'>
              <div className='img'>
                <img src={herologo} alt="" />
              </div>
              <span className='generalHeader'>WELCOME</span>
              <span className='para'>
              Empowering abilities & Enriching lives, together we forge inclusion, sparking potential, and transforming futures
              </span>
              <a className='btn-wbc btn-wbc-primary' href='/#contactus'>
                CONTACT US
              </a>
            </div>
          </div>
        </div>
      </section>
      <OurApproach/>
      <Gallery/>
      <Reviews/>
      <ContactUs/>
    </>
  )
}

export default Home