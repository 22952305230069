import React, { useRef, useState } from "react";
import Slider from "react-slick";

import gall01 from "../../static/home/gallery/gall1.webp";
import gall02 from "../../static/home/gallery/gall2.webp";
import gall03 from "../../static/home/gallery/gall3.webp";
import gall04 from "../../static/home/gallery/gall4.webp";
import gall05 from "../../static/home/gallery/gall5.webp";
import gall06 from "../../static/home/gallery/gall6.webp";
import gall07 from "../../static/home/gallery/gall7.webp";
import gall08 from "../../static/home/gallery/gall8.webp";
import gall09 from "../../static/home/gallery/gall9.webp";
import gall10 from "../../static/home/gallery/gall10.webp";

function Gallery() {
  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    customPaging: function (i) {
      return (
        <a>
          <img
            src={require(`../../static/home/gallery/gall${i + 1}.webp`)}
            alt="asd"
          />
        </a>
      );
    },
  };
  return (
    <section className="gallery">
      <div className="container">
        <div className="generalHeader">PHOTO GALLERY</div>
      </div>
      <Slider {...settings}>
        <div className="gal-item">
          <img src={gall01} alt="a" />
        </div>
        <div className="gal-item">
          <img src={gall02} alt="a" />
        </div>
        <div className="gal-item">
          <img src={gall03} alt="a" />
        </div>
        <div className="gal-item">
          <img src={gall04} alt="a" />
        </div>
        <div className="gal-item">
          <img src={gall05} alt="a" />
        </div>
        <div className="gal-item">
          <img src={gall06} alt="a" />
        </div>
        <div className="gal-item">
          <img src={gall07} alt="a" />
        </div>
        <div className="gal-item">
          <img src={gall08} alt="a" />
        </div>
        <div className="gal-item">
          <img src={gall09} alt="a" />
        </div>
        <div className="gal-item">
          <img src={gall10} alt="a" />
        </div>
      </Slider>
    </section>
  );
}

export default Gallery;
