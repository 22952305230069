import React from 'react'
import ScrollWrapper from './ScrollWrapper'
import { Route, Routes } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Home from '../pages/Home'

function router() {
  return (
    <>
        <ScrollWrapper>
            <div  className="d-flex flex-column vh-100 main-clm">
            <Header />
              <div className="f-body">
                <Routes>
                  <Route exact path="/" element={<Home />} />  
                </Routes>
              </div>

              <Footer />
            </div>
        </ScrollWrapper>
    </>
  )
}

export default router